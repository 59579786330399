import clsx from "clsx"
import LinkedIn from "./Icons/LinkedIn"

const Footer = ({className,...restProps}: React.HTMLAttributes<HTMLDivElement>) => {
    return (
        <div className={clsx(
            className,
            "flex justify-between text-sm",
            'w-full lg:max-w-7xl -translate-x-1/2 left-1/2',
            'absolute bottom-[55px] left-0'
        )}
        {...restProps}
    >
            <div className="">
                <p>Legal Disclaimer⼁Site Map⼁Advisory Message⼁Contact Us</p>
                <p>Copyright 2024 © D3XCHANG3 All Rights Reserved</p>
            </div>

            <LinkedIn fill="#fff"/>
        </div>
    )
}

export default Footer
