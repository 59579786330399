import { Button } from '@pancakeswap/uikit';
import clsx from 'clsx';
import Footer from 'components/Footer';
import Image from 'next/image';
import { useRouter } from 'next/router';

const Home: React.FC<React.PropsWithChildren> = () => {
  const router = useRouter()

  return (
    <div className='relative h-screen'>
      <div className={clsx(
        "lg:pb-0 pb-[50px] text-white text-lg",
        'lg:max-w-7xl mx-auto pt-16'
      )}>
        <div className='hidden lg:block pt-14 px-4 text-[xxx-large]'>
          <Image className='w-1/2 -ml-[25px]' src='/images/logo.png' alt='' width={2802} height={516} />
        </div>

        <div className='px-4 md:w-[85%] lg:w-[70%]'>
          <p className='text-3xl'>
            Revolutionising Asset Trading with Decentralised Solutions
          </p>

          <p className='mt-8'>
            The groundbreaking platform that is set to transform the way investors trade assets. Secure, transparent, and efficient protocol offers a range of innovative solutions and value-added services, all aimed at optimising liquidity, reducing transaction costs, and improving transparency.
          </p>

          <Button
            className='mt-6'
            onClick={() => router.push('/swap')}
          >
            Start Trading
          </Button>
        </div>

        <Footer/>
      </div>

      <div className={clsx(
          'fixed left-0 top-0 bottom-0 right-0 z-[-1]'
        )}>
          <video autoPlay muted controls={false} loop className='h-full w-full object-cover'>
              <source src="/video-bg.mp4" type="video/mp4" />
          </video>
      </div>
    </div>
  )
}

export default Home
